@charset "UTF-8";
/*Background: #efefef*/

@font-face {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  src: url("lib/fonts/Lato-Medium.eot?v=2.015");
  src: url("lib/fonts/Lato-Medium.eot?v=2.015#iefix")
      format("embedded-opentype"),
    url("lib/fonts/Lato-Medium.ttf?v=2.015") format("truetype"),
    url("lib/fonts/Lato-Medium.woff?v=2.015") format("woff"),
    url("lib/fonts/Lato-Medium.woff") format("woff");
}
@font-face {
  font-family: Lato-bold;
  font-style: bold;
  font-weight: bold;
  src: url("lib/fonts/Lato-Bold.eot?v=2.015");
  src: url("lib/fonts/Lato-Bold.eot?v=2.015#iefix") format("embedded-opentype"),
    url("lib/fonts/Lato-Medium.ttf?v=2.015") format("truetype"),
    url("lib/fonts/Lato-Medium.woff?v=2.015") format("woff"),
    url("lib/fonts/Lato-Medium.woff") format("woff");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Lato";
}
code {
  font-family: Lato, Arial, serif;
}
